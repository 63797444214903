<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-0">
        <b-overlay
          :show="load"
          spinner-variant="main"
          spinner-type="grow"
          spinner-small
        >
          <BoxDetail />
        </b-overlay>
      </div>

      <!-- -------------------------- -->
      <div class="col-12 m-0 p-0">
        <b-overlay
          :show="load"
          spinner-variant="main"
          spinner-type="grow"
          spinner-small
        >
          <ImageAndVideo />
        </b-overlay>
      </div>

      <!-- -------------------------- -->
      <div class="col-12 m-0 p-0">
        <b-overlay
          :show="load"
          spinner-variant="main"
          spinner-type="grow"
          spinner-small
        >
          <Generral />
        </b-overlay>
      </div>

      <!-- -------------------------- -->
      <div class="col-12 m-0 p-0">
        <b-overlay
          :show="load"
          spinner-variant="main"
          spinner-type="grow"
          spinner-small
        >
          <Descript />
        </b-overlay>
      </div>

      <!-- -------------------------- -->

      <div
        class="col-12 m-0 shadow-lg  p-4 mb-3 bg-white rounded sticky-bottom"
      >
        <div class="row m-0 p-0 justify-content-end">
          <button
            type="button"
            class="btn btn-sm px-4 bt-main1 mx-1"
            @click="
              $router.push({
                name: 'ProductInfo-ProductQR',
              })
            "
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-sm px-4 bt-bnb mx-1"
            @click="view()"
          >
            View
          </button>
          <button
            type="button"
            class="btn btn-sm bt-main  mx-1 px-4"
            v-if="data.enrich !== 'A'"
            @click="save()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BoxDetail from "@/components/Products/QRInfo/ProductQR/Content/BoxDetail.vue";
import ImageAndVideo from "@/components/Products/QRInfo/ProductQR/Content/ImageAndVideo.vue";
import Generral from "@/components/Products/QRInfo/ProductQR/Content/Generral.vue";
import Descript from "@/components/Products/QRInfo/ProductQR/Content/Descript.vue";
import { updatepimproduct_qr } from "@/api/Product";
export default {
  components: { BoxDetail, ImageAndVideo, Generral, Descript },
  data() {
    return {
      load: true,
      data: {
        enrich: "",
      },
    };
  },
  watch: {
    com_data: function(v) {
      this.set_init();
    },
  },
  computed: {
    com_data: function() {
      return this.$store.getters["enrichproductdetailQRActions/get_data"];
    },
  },
  mounted() {
    this.get_data();
  },
  methods: {
    async view() {
      let routeData = this.$router.resolve({
        name: "ProductInfo-ProductQR-Preview",
        params: { skcode: this.$route.params.skcode },
      });
      window.open(routeData.href, "_blank");
    },
    async get_data() {
      if (!!this.$route?.params?.skcode) {
        this.$store.dispatch(
          "enrichproductdetailQRActions/getpimenrichproductQR_data",
          this.$route?.params?.skcode
        );
      }
    },
    async save() {
      try {
        let mss = `ยืนยันการบันทึก / Confirm Recording?`;
        let confirm = await this.$serviceMain.showConfirmAlert(this, mss);
        if (confirm) {
          let data = await this.$store.getters[
            "enrichproductdetailQRActions/get_data"
          ];

          // ---------------------- save img -------------------
          let url_img = `${this.$cons.URL_TWD_IMG}img/save`;

          var config_axios = {
            method: "post",
            url: url_img,
            headers: {
              "Content-Type": "application/json",
            },
            data: data,
          };

          this.$store.dispatch("loadingActions/onload");

          let save_img = await this.$axios(config_axios);

          // ---------------------- save img -------------------

          await updatepimproduct_qr(data);
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
          this.get_data();
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async set_init() {
      let data = await this.$store.getters[
        "enrichproductdetailQRActions/get_data"
      ];
      if (!!data) {
        this.load = false;
        let { enrich } = data;
        this.data.enrich = enrich;
      }
    },
  },
  async beforeRouteLeave(to, from, next) {
    // const answer = window.confirm(
    //   "คุณต้องการที่ออกหน้านี้? คุณมีการเปลี่ยนแปลงที่ยังไม่ได้บันทึก! / Do you really want to leave? you have unsaved changes!"
    // );
    let confirm = await this.$serviceMain.showConfirmAlert(
      this,
      "คุณต้องการที่ออกหน้านี้? คุณมีการเปลี่ยนแปลงที่ยังไม่ได้บันทึก! / Do you really want to leave? you have unsaved changes!"
    );
    if (confirm) {
      next();
    } else {
      next(false);
    }
  },
};
</script>

<style scope>
.sticky-bottom {
  position: -webkit-sticky;
  position: sticky;
  z-index: 5;
  bottom: 0;
}
</style>
