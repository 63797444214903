<template>
  <div class="row w-100 m-0 p-0">
    <div class="col-12 m-0 shadow p-4 mb-3 bg-white rounded">
      <p class="font-1-2s font-600">รูปภาพ</p>

      <div class="row">
        <div class="col-12 col-lg-3 text-right">
          <p>ภาพประกอบ ({{ data.fileUploads.length }}/1)</p>
          <input
            type="file"
            accept="image/*"
            multiple
            @change="requestUploadFile($event)"
            ref="file"
            style="display: none"
          />

          <div class="row justify-content-end">
            <div
              id="droppable"
              type="button"
              class="col-10 border drag rounded px-0 py-5 m-0"
              :class="[active === true ? 'dragging' : '']"
              v-cloak
              @drop.prevent="
                data.fileUploads.length < 1 &&
                $serviceMain.chechnull(data.CATCODE) !== ''
                  ? dragFile($event)
                  : alertimg()
              "
              @click="
                data.fileUploads.length < 1 &&
                $serviceMain.chechnull(data.CATCODE) !== ''
                  ? $refs.file.click()
                  : alertimg()
              "
              @dragover.prevent="
                data.fileUploads.length < 1 &&
                $serviceMain.chechnull(data.CATCODE) !== ''
                  ? dragHover($event)
                  : alertimg()
              "
              @dragleave.prevent="
                data.fileUploads.length < 1 &&
                $serviceMain.chechnull(data.CATCODE) !== ''
                  ? dragEnd($event)
                  : alertimg()
              "
            >
              <p class="text-center m-0 p-0" v-html="$svg_icon.add"></p>
              <p class="font-0-7s   p-0 m-0 font-600 text-center">
                Drop file or click to upload
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-9">
          <div class="row">
            <div
              class="col-2"
              type="button"
              v-for="(e, i) in data.fileUploads"
              :key="i"
            >
              <div
                class="w-100 m-0 p-1 mb-1 rounded border position-relative box"
                :class="[
                  check_hw(e.b64.size.h, e.b64.size.w)
                    ? 'good-border'
                    : 'fail-border',
                ]"
              >
                <img
                  :src="e.save ? e.b64.img : e.link"
                  class="img-fluid box-img"
                  alt=""
                />
                <div class="action-img px-3 m-0">
                  <p class="m-0 p-0 d-flex justify-content-between">
                    <span
                      type="button"
                      v-html="$svg_icon.zoom"
                      @click="zooming(e.link || e.b64.img)"
                    ></span>
                    <span
                      type="button"
                      v-html="$svg_icon.removesm"
                      @click="remove(e)"
                    ></span>
                  </p>
                </div>
              </div>
              <p class="font-0-65s">
                {{ e.name }}
                <span
                  :class="[
                    check_hw(e.b64.size.h, e.b64.size.w) ? '' : 'text-danger',
                  ]"
                  >{{ `(${e.b64.size.w} x ${e.b64.size.h})` }}</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modalimg :key="reModalimg" :b64="dataModalimg" />
  </div>
</template>

<script>
import Modalimg from "@/components/Products/EnrichProductDetail/Modalimg.vue";
export default {
  components: {
    Modalimg,
  },
  data() {
    return {
      active: false,
      reModalimg: Date.now(),
      dataModalimg: "",
      data: {
        fileUploads: [],
        images: "",
        CATCODE: "",
        skCode: "",
      },
    };
  },
  watch: {
    com_get_data: function(v) {
      this.get_data();
    },
    data: {
      async handler(val) {
        let { CATCODE, fileUploads, images } = val;
        let get_data = await this.$store.getters[
          "enrichproductdetailQRActions/get_data"
        ];
        if (!!get_data) {
          get_data.fileUploads = fileUploads;
          this.$store.dispatch(
            "enrichproductdetailQRActions/set_data",
            get_data
          );
        }
      },
      deep: true,
    },
  },
  computed: {
    com_get_data: function() {
      return this.$store.getters["enrichproductdetailQRActions/get_data"];
    },
  },
  mounted() {
    this.get_data();
  },
  methods: {
    alertimg() {
      this.$serviceMain.showErrorAlert(
        this,
        `ระบบรองรับรูปภาพเเค่ 1 รูป /สินค้าต้องมี CAT MMS เเล้ว เท่านั้น`
      );
    },
    async get_data() {
      // this.redata();

      let data = await this.$store.getters[
        "enrichproductdetailQRActions/get_data"
      ];

      if (data !== null) {
        const { images, fileUploads, CATCODE, skCode } = data;

        this.data = {
          images: images,
          fileUploads: fileUploads,
          CATCODE: CATCODE,
          skCode: skCode,
        };
      }
    },
    zooming(img) {
      // console.log(img);
      this.reModalimg = Date.now();
      this.dataModalimg = img;
      setTimeout(() => {
        this.$bvModal.show("Modalimg");
      }, 100);
    },
    remove(e) {
      let index = this.data.fileUploads.findIndex((i) => i == e);
      if (index != -1) {
        if (confirm(`Do you really want to delete ${e.file.name}?`)) {
          this.data.fileUploads.splice(index, 1);
          // this.change();
        }
      }
    },
    convert_imgbase64(file) {
      return new Promise(async (resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          var image = new Image();
          image.src = reader.result;
          image.onload = function() {
            var height = this.height;
            var width = this.width;
            resolve({ img: reader.result, size: { h: height, w: width } });
          };
        };
        reader.onerror = (error) => reject(error);
      });
    },
    removefile(i) {
      this.data.fileUploads.splice(i, 1);
    },
    clear() {
      this.$refs.file.value = null;
    },
    check_hw(h, w) {
      if (Number(h) == 1200 && Number(w) == 1200) {
        return true;
      } else {
        return false;
      }
    },
    async requestUploadFile(e) {
      try {
        let files = e.target.files;

        for (let i in files) {
          if (i !== "item" && i !== "length") {
            let type = String(
              files[i].type.split(`image/`)[
                files[i].type.split(`image/`).length - 1
              ]
            )
              .toLocaleUpperCase()
              .trim();

            if (type !== "PNG" && type !== "JPG" && type !== "JPEG") {
              throw `Support .png, .jpg, .jpeg only`;
            }
            if (this.data.fileUploads.length < 1) {
              let _type = String(files[i].name).split(".")[
                String(files[i].name).split(".").length - 1
              ];
              this.data.fileUploads.push({
                file: files[i],
                // name: files[i].name,
                name: `${this.data.skCode}_QR.${_type}`,
                b64: await this.convert_imgbase64(files[i]),
                fixed: false,
                index: this.data.fileUploads.length,
                save: true,
              });
            } else {
              throw `สามารถอัพโหลดได้ 1 รูป เท่านั้น`;
            }
          }
        }
        // console.log(this.data.fileUploads);
        this.clear();
      } catch (error) {
        this.clear();

        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async dragFile(e) {
      try {
        // console.log(e.dataTransfer.files);
        let files = e.dataTransfer.files;

        var filenames = [];
        Object.keys(files).forEach(function(key, index) {
          filenames.push(files[index].name);

          let type = String(
            files[index].type.split(`image/`)[
              files[index].type.split(`image/`).length - 1
            ]
          )
            .toLocaleUpperCase()
            .trim();

          if (type !== "PNG" && type !== "JPG" && type !== "JPEG") {
            throw `Support .png, .jpg, .jpeg only`;
          }
        });

        for await (let e of filenames.sort()) {
          let check = Object.values(files).find((obj) => {
            return String(obj.name).trim() == e;
          });
          // console.log(check);
          if (check !== undefined) {
            let { type, name } = check;
            let check_img = type.split("/")[0];

            if (check_img == "image") {
              if (this.data.fileUploads.length < 1) {
                let _type = String(name).split(".")[
                  String(name).split(".").length - 1
                ];
                this.data.fileUploads.push({
                  file: check,
                  // name: name,
                  name: `${this.data.skCode}_QR.${_type}`,
                  b64: await this.convert_imgbase64(check),
                  fixed: false,
                  index: this.data.fileUploads.length,
                  save: true,
                });
              } else {
                throw `สามารถอัพโหลดได้ 1 รูป เท่านั้น`;
              }
            }
          }
        }

        this.clear();
        this.active = false;
      } catch (error) {
        this.clear();
        this.active = false;
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    dragHover(e) {
      // e.target.classList.add("dragging");
      this.active = true;
    },
    dragEnd(e) {
      // e.target.classList.remove("dragging");
      setTimeout(() => {
        this.active = false;
      }, 100);
    },
  },
};
</script>

<style></style>
