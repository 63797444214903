<template>
  <div class="row w-100 m-0 p-0">
    <div class="col-12 m-0 shadow p-4 mb-3 bg-white rounded">
      <p class="font-1-2s font-600">คำอธิบายของสินค้า</p>

      <button class="  btn bt-main mx-1 px-3" @click="CallModallinkfile()">
        <span v-html="$svg_icon.link" class="mx-1"></span>Link Product Files
        List
      </button>

      <div class="row px-3 my-3">
        <div class="col-12">
          <p>Product Short Description</p>
          <hr />
        </div>

        <div class="col-6">
          <p class="badge badge-primary">TH</p>
          <tinymce
            class="shadow bg-white rounded"
            id="DescriptionTH_QR"
            :plugins="plugins"
            :toolbar="toolbar"
            :other_options="options"
            v-model="data.shortDesc1TH"
          ></tinymce>
        </div>
        <div class="col-6">
          <p class="badge badge-info">EN</p>
          <tinymce
            class="shadow bg-white rounded"
            id="DescriptionEN_QR"
            :plugins="plugins"
            :toolbar="toolbar"
            :other_options="options"
            v-model="data.shortDesc1EN"
          ></tinymce>
        </div>
      </div>
      <!-- -------------------- -->
      <div class="row px-3">
        <div class="col-12">
          <p>Product Long Description</p>
          <hr />
        </div>

        <div class="col-6">
          <p class="badge badge-primary">TH</p>
          <tinymce
            class="shadow bg-white rounded"
            id="LonngDescriptionTH_QR"
            :plugins="plugins"
            :toolbar="toolbar"
            :other_options="options"
            v-model="data.longDescTH"
          ></tinymce>
        </div>
        <div class="col-6">
          <p class="badge badge-info">EN</p>
          <tinymce
            class="shadow bg-white rounded"
            id="LonngDescriptionEN_QR"
            :plugins="plugins"
            :toolbar="toolbar"
            :other_options="options"
            v-model="data.longDescEN"
          ></tinymce>
        </div>
      </div>
    </div>
    <Modallinkfile :key="reModallinkfile" />
  </div>
</template>

<script>
import tinymce from "vue-tinymce-editor";
import Modallinkfile from "@/components/Products/QRInfo/ProductQR/Content/Descrpit/Modallinkfile.vue";
export default {
  components: { Modallinkfile, tinymce },
  data() {
    return {
      // Tinymce Editor
      plugins: [
        "advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker",
        "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
        "save table contextmenu directionality emoticons template paste textcolor",
      ],
      toolbar:
        "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media fullpage | forecolor backcolor emoticons",
      options: {
        height: 150,
        relative_urls: false,
        remove_script_host: false,
        convert_urls: true,
        style_formats: [
          { title: "Bold text", inline: "b" },
          { title: "Red text", inline: "span", styles: { color: "#ff0000" } },
          { title: "Red header", block: "h1", styles: { color: "#ff0000" } },
          { title: "Example 1", inline: "span", classes: "example1" },
          { title: "Example 2", inline: "span", classes: "example2" },
          { title: "Table styles" },
          { title: "Table row 1", selector: "tr", classes: "tablerow1" },
        ],
      },
      // End Tinymce Editor
      reModallinkfile: Date.now() + 120,
      data: {
        shortDesc1TH: "",
        shortDesc1EN: "",
        longDescEN: "",
        longDescTH: "",
      },
    };
  },
  watch: {
    com_get_data: function(v) {
      this.get_data();
    },
    data: {
      async handler(val) {
        let { shortDesc1TH, shortDesc1EN, longDescEN, longDescTH } = val;
        let get_data = await this.$store.getters[
          "enrichproductdetailQRActions/get_data"
        ];

        if (!!get_data) {
          get_data.shortDesc1TH = shortDesc1TH;
          get_data.shortDesc1EN = shortDesc1EN;

          get_data.longDescTH = longDescTH;
          get_data.longDescEN = longDescEN;
          this.$store.dispatch(
            "enrichproductdetailQRActions/set_data",
            get_data
          );
        }
      },
      deep: true,
    },
  },
  computed: {
    com_get_data: function() {
      return this.$store.getters["enrichproductdetailQRActions/get_data"];
    },
  },
  mounted() {
    this.get_data();
  },
  methods: {
    CallModallinkfile() {
      this.reModallinkfile = Date.now();
      setTimeout(() => {
        this.$bvModal.show("Modallinkfile");
      }, 100);
    },
    async get_data() {
      // this.redata();

      let data = await this.$store.getters[
        "enrichproductdetailQRActions/get_data"
      ];

      if (!!data) {
        const { shortDesc1TH, shortDesc1EN, longDescEN, longDescTH } = data;

        this.data = {
          shortDesc1TH: shortDesc1TH || "",
          shortDesc1EN: shortDesc1EN || "",
          longDescTH: longDescTH || "",
          longDescEN: longDescEN || "",
        };
      }
    },
  },
};
</script>

<style></style>
