<template>
  <div class="row w-100 m-0 p-0">
    <div class="col-12 m-0 shadow p-4 mb-3 bg-white rounded">
      <p class="font-1-2s font-600">ข้อมูลทั่วไป</p>
      <!-- ------------------------ -->
      <div class="row form-inline">
        <div class="col-12 col-lg-2 mb-4 text-right">ชื่อสินค้า</div>
        <div class="col-12 col-lg-10 mb-4">
          <div class="row">
            <div class="col-6">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">TH</span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  placeholder="ชื่อสินค้า TH"
                  maxlength="100"
                  v-model="data.prNameTH"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">EN</span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  placeholder="ชื่อสินค้า EN"
                  maxlength="100"
                  v-model="data.prNameEN"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ------------------------------- -->
      <div class="row form-inline">
        <div class="col-12 col-lg-2 mb-4 text-right">MMS Category</div>
        <div class="col-12 col-lg-10 mb-4">
          <div class="row">
            <div class="col-12">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">{{ data.CATCODE }} </span>
                </div>
                <input
                  readonly="readonly"
                  type="text"
                  class="form-control"
                  placeholder="MMS Category Name"
                  v-model="data.CATNAME"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      data: {
        prNameTH: "",
        prNameEN: "",
        CATCODE: "",
        CATNAME: "",
      },
    };
  },
  watch: {
    com_get_data: function(v) {
      this.get_data();
    },
    data: {
      async handler(val) {
        let { prNameTH, prNameEN } = val;

        let get_data = await this.$store.getters[
          "enrichproductdetailQRActions/get_data"
        ];
        if (!!get_data) {
          get_data.prNameTH = prNameTH;
          get_data.prNameEN = prNameEN;
          this.$store.dispatch(
            "enrichproductdetailQRActions/set_data",
            get_data
          );
        }
      },
      deep: true,
    },
  },
  computed: {
    com_get_data: function() {
      return this.$store.getters["enrichproductdetailQRActions/get_data"];
    },
  },
  mounted() {
    this.get_data();
  },
  methods: {
    async get_data() {
      // this.redata();

      let data = await this.$store.getters[
        "enrichproductdetailQRActions/get_data"
      ];

      if (data !== null) {
        const { prNameTH, prNameEN, CATCODE, CATNAME } = data;

        this.data = {
          prNameTH: prNameTH,
          prNameEN: prNameEN,
          CATCODE: CATCODE,
          CATNAME: CATNAME,
        };
      }
    },
  },
};
</script>

<style></style>
