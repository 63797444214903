<template>
  <div class="row w-100 m-0 p-0">
    <div class="col-12 m-0 shadow p-4 mb-3 bg-white rounded">
      <div class="row">
        <div class="col-10">
          <p class=" ">
            <span class="font-600 ">ชื่อสินค้า : </span
            >{{ `${data.skCode} - ${data.prNameTH}` }}
          </p>
          <p class=" ">
            <span class="font-600 ">CatCode : </span>{{ data.CATCODE }}
          </p>
        </div>
        <div class="col-2 font-0-7s ">
          <p class="text-muted text-right m-0 p-0">
            อัพเดทล่าสุด {{ data.lastUpdate120 }}
          </p>
          <p class="text-muted text-right m-0 p-0">
            โดย {{ data.updateByUser }}
          </p>
        </div>
        <!-- ---------------- -->
        <div class="col-12 col-lg-4">
          <p class=" font-0-8s">
            <span class="font-600 ">Brand : </span>{{ data.brand }}
          </p>
        </div>
        <!-- ---------------- -->
        <div class="col-12 col-lg-4">
          <p class=" font-0-8s">
            <span class="font-600 ">Enrich : </span>{{ data.enrich }}
          </p>
        </div>
        <!-- ---------------- -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {
        CATCODE: "",
        brand: "",
        enrich: "",
        prNameEN: "",
        skCode: "",
        updateByUser: "",
        lastUpdate120: "",
      },
    };
  },
  watch: {
    com_data: function(v) {
      this.set_init();
    },
  },
  computed: {
    com_data: function() {
      return this.$store.getters["enrichproductdetailQRActions/get_data"];
    },
  },
  mounted() {
    this.set_init();
  },
  methods: {
    async set_init() {
      let data = await this.$store.getters[
        "enrichproductdetailQRActions/get_data"
      ];
      if (!!data) {
        let {
          CATCODE,
          brand,
          enrich,
          prNameTH,
          skCode,
          updateByUser,
          lastUpdate120,
        } = data;
        this.data.CATCODE = CATCODE;
        this.data.brand = brand;
        this.data.enrich = enrich;
        this.data.prNameTH = prNameTH;
        this.data.skCode = skCode;
        this.data.updateByUser = updateByUser;
        this.data.lastUpdate120 = lastUpdate120;
      }
    },
  },
};
</script>

<style></style>
