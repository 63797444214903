var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row w-100 m-0 p-0"},[_c('div',{staticClass:"col-12 m-0 shadow p-4 mb-3 bg-white rounded"},[_c('p',{staticClass:"font-1-2s font-600"},[_vm._v("รูปภาพ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-3 text-right"},[_c('p',[_vm._v("ภาพประกอบ ("+_vm._s(_vm.data.fileUploads.length)+"/1)")]),_c('input',{ref:"file",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*","multiple":""},on:{"change":function($event){return _vm.requestUploadFile($event)}}}),_c('div',{staticClass:"row justify-content-end"},[_c('div',{staticClass:"col-10 border drag rounded px-0 py-5 m-0",class:[_vm.active === true ? 'dragging' : ''],attrs:{"id":"droppable","type":"button"},on:{"drop":function($event){$event.preventDefault();_vm.data.fileUploads.length < 1 &&
              _vm.$serviceMain.chechnull(_vm.data.CATCODE) !== ''
                ? _vm.dragFile($event)
                : _vm.alertimg()},"click":function($event){_vm.data.fileUploads.length < 1 &&
              _vm.$serviceMain.chechnull(_vm.data.CATCODE) !== ''
                ? _vm.$refs.file.click()
                : _vm.alertimg()},"dragover":function($event){$event.preventDefault();_vm.data.fileUploads.length < 1 &&
              _vm.$serviceMain.chechnull(_vm.data.CATCODE) !== ''
                ? _vm.dragHover($event)
                : _vm.alertimg()},"dragleave":function($event){$event.preventDefault();_vm.data.fileUploads.length < 1 &&
              _vm.$serviceMain.chechnull(_vm.data.CATCODE) !== ''
                ? _vm.dragEnd($event)
                : _vm.alertimg()}}},[_c('p',{staticClass:"text-center m-0 p-0",domProps:{"innerHTML":_vm._s(_vm.$svg_icon.add)}}),_c('p',{staticClass:"font-0-7s   p-0 m-0 font-600 text-center"},[_vm._v(" Drop file or click to upload ")])])])]),_c('div',{staticClass:"col-12 col-lg-9"},[_c('div',{staticClass:"row"},_vm._l((_vm.data.fileUploads),function(e,i){return _c('div',{key:i,staticClass:"col-2",attrs:{"type":"button"}},[_c('div',{staticClass:"w-100 m-0 p-1 mb-1 rounded border position-relative box",class:[
                _vm.check_hw(e.b64.size.h, e.b64.size.w)
                  ? 'good-border'
                  : 'fail-border' ]},[_c('img',{staticClass:"img-fluid box-img",attrs:{"src":e.save ? e.b64.img : e.link,"alt":""}}),_c('div',{staticClass:"action-img px-3 m-0"},[_c('p',{staticClass:"m-0 p-0 d-flex justify-content-between"},[_c('span',{attrs:{"type":"button"},domProps:{"innerHTML":_vm._s(_vm.$svg_icon.zoom)},on:{"click":function($event){return _vm.zooming(e.link || e.b64.img)}}}),_c('span',{attrs:{"type":"button"},domProps:{"innerHTML":_vm._s(_vm.$svg_icon.removesm)},on:{"click":function($event){return _vm.remove(e)}}})])])]),_c('p',{staticClass:"font-0-65s"},[_vm._v(" "+_vm._s(e.name)+" "),_c('span',{class:[
                  _vm.check_hw(e.b64.size.h, e.b64.size.w) ? '' : 'text-danger' ]},[_vm._v(_vm._s(("(" + (e.b64.size.w) + " x " + (e.b64.size.h) + ")")))])])])}),0)])])]),_c('Modalimg',{key:_vm.reModalimg,attrs:{"b64":_vm.dataModalimg}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }